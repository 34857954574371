import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchVisits(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/visits', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		recordAttendance(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/visits/${id}/attendances`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		}

	}
}